<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Role</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <div class="card-body">
              <div class="card-header-form">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="role_name"
                    readonly
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h4>Table Menu</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <button class="btn btn-success" @click="updateStatusTrue">
                    True
                  </button>
                  <button
                    class="btn btn-danger ml-1"
                    @click="updateStatusFalse"
                  >
                    False
                  </button>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="card-body">
                <v-progress-circular
                  v-if="loading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <v-data-table
                  v-if="!loading"
                  show-select
                  :items-per-page="1000"
                  :headers="headers"
                  hide-default-footer
                  v-model="selected"
                  :items="roles.menu_child"
                  item-key="menu_child_id"
                  :search="search"
                  class="elevation-1"
                >
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  name: "role",
  data() {
    return {
      search: "",
      selected: [],
      name: "Show Role",
      role_name: "",
      roles: [],
      errors: {},
      loading: true,
      test: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      axios
        .get(this.$store.state.api + "role/" + id, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          this.loading = false;
          this.role_name = res.data.data.role.name;
          this.roles = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    updateStatusFalse() {
      let formData = new FormData();
      this.crudServiceId.forEach((item) =>
        formData.append("crud_service_id[]", item)
      );
      axios
        .post(this.$store.state.api + "crud_service/update", formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your Status has been updated!");
          this.getData();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    updateStatusTrue() {
      let formData = new FormData();
      formData.set("role_id", this.roles.role.id);
      this.menuChildId.forEach((item) =>
        formData.append("menu_child_id[]", item)
      );
      axios
        .post(this.$store.state.api + "crud_service/store", formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your Status has been updated!");
          this.getData();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    deleteRole(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "role/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.role = this.role.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => console.log(err));
          swal("Poof! Your role file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your role file is safe!");
        }
      });
    },
  },
  computed: {
    menuChildId() {
      let list = [];
      this.selected.map(function(value, key) {
        list.push(value.menu_child_id);
      });
      return list;
    },
    crudServiceId() {
      let list = [];
      this.selected.map(function(value, key) {
        list.push(value.crud_service_id);
      });
      return list;
    },
    headers() {
      return [
        {
          text: "Menu Name",
          value: "menu_name",
        },
        {
          text: "Path",
          value: "path",
        },
        {
          text: "Status",
          value: "status",
        },
        // { text: "Action" },
      ];
    },
  },
};
</script>
